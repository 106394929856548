export const permissions = [ 
    "ADMIN",
    "OPERATOR",
    "DRIVER",
    "CLIENT"
]

export const orderStatuses = [
    "PENDING",
    "ASSIGNED",
    "ACCEPTED",
    "ONCOURSE",
    "COMPLETED",
    "CANCELLED"
]

export const userTypes = [
    "ENTERPRISE",
    "CLIENT",
    "DRIVER",
    "ADMIN",
    "OFFICE"
]

export const userTypesToCreate = [
    "DRIVER",
    "ADMIN",
    "OFFICE"
]