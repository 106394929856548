import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import seven from "../../assets/images/user/7.jpg";
import { MyProfile,Password, EditProfile, Username,  UpdateProfile, EmailAddress } from "../../constant";
import useCrud from "../../hooks/use_crud";
import LoadingSpinner from "../my-components/loading_spinner";


const UserEdit = () => {

  const [loading, setLoading] = useState(false)
  const {findById, edit} = useCrud({url : 'auth', setLoading : setLoading, firstLoad: false})
  const [data, setData] = useState({roles:[{name:"none"}]});
  const [updateData, setUpdateData] = useState({roles:[{name:"none"}]});


  useEffect(() => {
   
    findById(localStorage.getItem("userId")).then(user => {
      setData(user)
      setUpdateData(user)
    })
    
  }, []);

  return (
    <Fragment>
      <br/>
      <div className="container-fluid">
        <div className="edit-profile">
          <div className="row">
            <div className="col-lg-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">{MyProfile}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <form>
                    <div className="row mb-2">
                      <div className="col-auto">
                        <img className="img-70 rounded-circle" alt="" src={seven} />
                      </div>
                      <div className="col">
                        <h3 className="mb-1">{data.username}</h3>
                        <p className="mb-4">{data.roles[0].name}</p>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="form-label">{EmailAddress+": "+ data.email}</label>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="card">
                <div className="card-header">
                  <h4 className="card-title mb-0">{EditProfile}</h4>
                  <div className="card-options">
                    <a className="card-options-collapse" href="javascript" data-toggle="card-collapse">
                      <i className="fe fe-chevron-up"></i>
                    </a>
                    <a className="card-options-remove" href="javascript" data-toggle="card-remove">
                      <i className="fe fe-x"></i>
                    </a>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-6 col-md-3">
                      <div className="form-group mb-3">
                        <label className="form-label">{Username}</label>
                        <input className="form-control" type="text" placeholder="Username" value={updateData.username} onChange={(evt)=> setUpdateData({...updateData,username:evt.target.value})} />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <div className="form-group mb-3">
                        <label className="form-label">{Password}</label>
                        <input className="form-control" type="password" placeholder="Password" value={updateData.password? updateData.password : ""} onChange={(evt)=> setUpdateData({...updateData,password:evt.target.value})} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer text-end">
                  <button className="btn btn-primary" type="button" onClick={async ()=>{
                    await edit({
                    id:updateData.id,
                    username: updateData.username,
                    password: updateData.password,
                    roles: updateData.roles
                    })
                    
                    findById(localStorage.getItem("userId")).then(user => {
                      setData(user)
                    })

                    }}>
                    {UpdateProfile}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <LoadingSpinner isVisible={loading} />
    </Fragment>
  );
};

export default UserEdit;
